$(document).ready(function () {
  $('.form__drop-input.drag-card').on({
    dragenter: function (event) {
      event.preventDefault();
      $(this).closest('.form__drop-body').addClass('form__drop-body--dragover');
    },

    dragleave: function (event) {
      event.preventDefault();
      $(this).closest('.form__drop-body').removeClass('form__drop-body--dragover');
    },

    drop: function (event) {
      // event.preventDefault();
      // event.stopPropagation();
      $(this).closest('.form__drop-body').removeClass('form__drop-body--dragover');

      $('.form__drop--card .form__drop-body').hide();
      $('.form__drop--card .form__drop-preview').show();

      let file = event.originalEvent.dataTransfer.files;
      let reader = new FileReader();

      reader.onload = function (event) {
        let $img = $('.form__drop-box .form__drop-image');
        if ($img.length < 1) {
          $img = $('<img class="form__drop-image" />').appendTo('.form__drop-box');
        }
        $img.attr('src', event.target.result);
      };

      if (file.length > 0) {
        reader.readAsDataURL(file[0]);
      }
    }
  });

  function readURL(input) {
    if (input.files && input.files[0]) {
      $('.form__drop--card .form__drop-preview').show();
      $('.form__drop--card .form__drop-body').hide();

      let reader = new FileReader();
      reader.onload = function (e) {
        let $img = $('.form__drop-box .form__drop-image');
        if ($img.length < 1) {
          $img = $('<img class="form__drop-image" />').appendTo('.form__drop-box');
        }
        $img.attr('src', event.target.result);
      };

      reader.readAsDataURL(input.files[0]);
    }
  }

  $('.form__drop--card .form__drop-input').change(function () {
    readURL(this);
  });
});
