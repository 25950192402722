// этот файл только для шеринга
window.sharingProxy = {};
(function ($) {
  sharingProxy.pre = {
    shareId: 0, openedWindow: '', type: '', link: '', share: '', cId: 0, trackInterval: null,
    redirect_uri: function (provider) {
      return location.href.replace(/(http[^\/]+\/\/[^\/]+\/).+/, '$1') + 'auth/' + provider + '/sharing_callback';
    },
    // Шэринг
    socialShare: function (type, url, b, params) {
      var u = '';
      switch (type) {
        case 'vk':
          u = 'http://vkontakte.ru/share.php?url=' + encodeURIComponent(url);

          if (typeof params == 'object' && typeof params['title'] == 'string') {
            u += '&title=' + encodeURIComponent(params['title']);
          }
          if (typeof params == 'object' && typeof params['image'] == 'string') {
            u += '&image=' + encodeURIComponent(params['image']);
          }

          break;
        case 'fb':
          u = 'http://www.facebook.com/sharer.php?u=' + encodeURIComponent(url);
          break;
        case 'ok':
          u = 'https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&st.deprecated=0&st.shareUrl=' + encodeURIComponent(url);
          break;
        case 'mail':
          u = 'http://connect.mail.ru/share?url=' + encodeURIComponent(url);
          break;
        default:
          return false;
      }
      if (typeof b == "boolean" && b) {
        return u;
      }
      if (url != '') {
        return window.open(u, 'sharer', 'toolbar=0,status=0,width=626,height=436');
      }
      return false;
    }
  }

  // Шеригш через Facebook
  sharingProxy.FBlogin = function () {
    sharingProxy.pre.openedWindow = 'FB_SHARE';
    var _u = 'https://www.facebook.com/dialog/oauth?client_id=633791500905724&redirect_uri=' + encodeURIComponent(sharingProxy.pre.redirect_uri('facebook'));// + '&scope=email';

    // console.log(_u);
    sharingProxy.fixClick('fb');
    return sharingProxy.openWindow(_u, sharingProxy.pre.openedWindow, 640, 480);
  };
  sharingProxy.FBLoginCallback = function (coreResponse) {
    console.log('fb', coreResponse);
    if (typeof coreResponse == "object" && typeof coreResponse["user"] == "object") {
      sharingProxy.Success('fb');
    } else {
      sharingProxy.Error('Ошибка авторизации Facebook.');
    }
  }
  //

  // Шеринг через Vkontakte
  sharingProxy.VKlogin = function () {
    sharingProxy.pre.openedWindow = 'VK_SHARE';
    var _u = 'https://oauth.vk.com/authorize?client_id=6772813&scope=email&redirect_uri=' + encodeURIComponent(sharingProxy.pre.redirect_uri('vkontakte')) + '&response_type=code&v=5.53';

    // console.log(_u);
    sharingProxy.fixClick('vk');
    return sharingProxy.openWindow(_u, sharingProxy.pre.openedWindow, 640, 480);
  }
  sharingProxy.VKLoginCallback = function (coreResponse) {
    console.log('vk', coreResponse);
    if (typeof coreResponse == "object" && typeof coreResponse["user"] == "object") {
      sharingProxy.Success('vk');
    } else {
      sharingProxy.Error('Ошибка авторизации Вконтакте.');
    }
  }
  //

  // Шеринг через Одноклассники
  sharingProxy.OKlogin = function () {
    sharingProxy.pre.openedWindow = 'OK_SHARE';
    sharingProxy.fixClick('ok');
    return sharingProxy.openWindow('https://www.odnoklassniki.ru/oauth/authorize?client_id=' + 1273739264 +'&scope=' + encodeURIComponent('VALUABLE_ACCESS GET_EMAIL') + '&response_type=code&redirect_uri=' + encodeURIComponent(sharingProxy.pre.redirect_uri('odnoklassniki')), sharingProxy.pre.openedWindow, 640, 480);
  }
  sharingProxy.OKLoginCallback = function (coreResponse) {
    console.log('ok', coreResponse);
    if (typeof coreResponse == "object" && typeof coreResponse["user"] == "object") {
      sharingProxy.Success('ok');
    } else {
      sharingProxy.Error('Ошибка авторизации Odnoklassniki.');
    }
  }
  //

  sharingProxy.openWindow = function (url, name, width, height) {
    var leftvar = (screen.width - width) / 2;
    var topvar = (screen.height - height) / 2;

    sharingProxy.pre.opened_window = window.open('', name, "width=" + width + ",height=" + height + ",left=" + leftvar + ",top=" + topvar + ",status=no,toolbar=no,menubar=no,resizable=1");
    sharingProxy.pre.opened_window.document.location = url;
    sharingProxy.pre.opened_window.focus();

    return sharingProxy.pre.opened_window;
  }

  // фикс
  sharingProxy.fixClick = function (_type) {
    sharingProxy.pre.type = _type;
    // сохранить id поста и CID
    $.ajax({
      url: '/shares/ping/' + sharingProxy.pre.cId + '/' + sharingProxy.pre.type + '/?ts=' + (new Date).getTime(),
      success: function (response) {
        sharingProxy.pre.shareId = response.shareId;
      },
      dataType: "json"
    });
  };

  sharingProxy.Success = function () {
    // заменяем url
    sharingProxy.pre.opened_window.document.location = sharingProxy.pre.share;
    var interval = window.setInterval(function () {
      if (typeof sharingProxy.pre.opened_window == "undefined" || typeof sharingProxy.pre.opened_window.window == "undefined" || sharingProxy.pre.opened_window.window == null) {
        window.clearInterval(interval);

        if (sharingProxy.pre.shareId) {
          $.ajax({
            url: '/shares/store/' + sharingProxy.pre.shareId + '/?ts=' + (new Date).getTime(),
            success: function (response) {
              // @todo: типа, пошерили, но не факт…
              //jewelryModals.show(".js-modal__share-thanks");
            },
            dataType: "json"
          });
        }
      }
    }, 200);
  }

  sharingProxy.Error = function (message) {
    sharingProxy.pre.opened_window.close();
    jewelryModals.show(".js-modal__message", {
      title: "Внимание!",
      message: message
    });
  }

  // Инициализация страницы
  $(document).ready(function () {
    // Шеринг картинки
    $(document).on('click', '.personal__share .social__item', function (e) {
      e.preventDefault();

      let $t = $(this), $s = $t.closest(".social"),
        link = $s.data("link"), name = $s.data("name"), image = $s.data("image"),
        m = $t.get(0).className.match(/social__item--(vk|fb|ok|tg|wa|tl|em)/), s = 'vkontakte', _u, matches;

      //link = location.href.replace(/(http[^\/]+\/\/[^\/]+\/).+/, '$1') + link;
      sharingProxy.pre.cId = 0;
      if (typeof link !== 'undefined') {
        matches = link.match(/\/(\d+)\//);
        if (matches !== null) {
          sharingProxy.pre.cId = matches[1];
        }
      }

      if (m !== null) {
        if (m[1] === 'vk') {
          sharingProxy.VKlogin();
        } else if (m[1] === 'fb') {
          sharingProxy.FBlogin();
        } else if (m[1] === 'ok') {
          sharingProxy.OKlogin();
        } else {
          if (m[1] === 'tg') {
            _u = 'https://t-do.ru/share/url?url=' + encodeURIComponent(link) + '&text=' + encodeURIComponent('#GIFTBACK: ' + name);
          } else if (m[1] === 'wa') {
            _u = 'https://api.whatsapp.com/send?text=' + encodeURIComponent('#GIFTBACK: ' + name + '\n' + link);
          } else if (m[1] === 'tl') {
            _u = 'viber://forward?text=' + encodeURIComponent('#GIFTBACK: ' + name + '\n' + link);
          } else if (m[1] === 'em') {
            _u = 'mailto:?subject=' + encodeURIComponent('#GIFTBACK') + '&body=' + encodeURIComponent(name + '\n' + link);
          }
          $t.attr('href', _u).prop('href', _u);
          return true;
        }

        // окно шеринга
        link += '?utm_source=' + s + '&utm_media=share';
        sharingProxy.pre.shareId = 0;
        sharingProxy.pre.link = link;
        sharingProxy.pre.share = sharingProxy.pre.socialShare(m[1], link, true, {
          image: image,
          title: name
        });
        sharingProxy.pre.type = m[1];
      }
    });
  });

})(jQuery);
