// Инициализация
(function ($) {
  if (typeof window['multipart'] === "undefined") {
    window.multipart = {
      inited: false
    };
  }
  if (window.multipart.inited) {
    return false; // на случай turbolink исключим повторное срабатывание
  }
  $(document).ready(function () {
    // AJAX-формы c enctype=multipart/form-data
    $('form.ajaxFormSubmit').off('submit').on('submit', function (e) {
      let $f = $(this);
      if ($f.find("input[type=file]").length && $f.find("input[type=file]").val().length) {
        e.preventDefault();
        //$f.find('.form__error').hide();
        $f.ajaxForm({}).ajaxSubmit({
          error: function (xhr, status, error) {
            if (xhr.status === 413) {
              // отлавливаем 413-й код ответа
              let $i = $f.find('input[type=file]');
              if ($i.length) {
                $f.find('.js--note__errors').html('Размер загружаемого файла ограничен 10Мб.').show();
              }
            }
          }
        }).ajaxFormUnbind();
      }
    });
    window.multipart.inited = true;
  });
})(jQuery);

// jQuery Form Plugin version: 3.31.0-2013.03.27
!function (e) {
  "use strict";

  function t(t) {
    var r = t.data;
    t.isDefaultPrevented() || (t.preventDefault(), e(this).ajaxSubmit(r))
  }

  function r(t) {
    var r = t.target, a = e(r);
    if (!a.is("[type=submit],[type=image]")) {
      var n = a.closest("[type=submit]");
      if (0 === n.length) return;
      r = n[0]
    }
    var i = this;
    if (i.clk = r, "image" == r.type) if (void 0 !== t.offsetX) i.clk_x = t.offsetX, i.clk_y = t.offsetY; else if ("function" == typeof e.fn.offset) {
      var o = a.offset();
      i.clk_x = t.pageX - o.left, i.clk_y = t.pageY - o.top
    } else i.clk_x = t.pageX - r.offsetLeft, i.clk_y = t.pageY - r.offsetTop;
    setTimeout(function () {i.clk = i.clk_x = i.clk_y = null}, 100)
  }

  function a() {
    if (e.fn.ajaxSubmit.debug) {
      var t = "[jquery.form] " + Array.prototype.join.call(arguments, "");
      window.console && window.console.log ? window.console.log(t) : window.opera && window.opera.postError && window.opera.postError(t)
    }
  }

  var n = {};
  n.fileapi = void 0 !== e("<input type='file'/>").get(0).files, n.formdata = void 0 !== window.FormData;
  var i = !!e.fn.prop;
  e.fn.attr2 = function () {
    if (!i) return this.attr.apply(this, arguments);
    var e = this.prop.apply(this, arguments);
    return e && e.jquery || "string" == typeof e ? e : this.attr.apply(this, arguments)
  }, e.fn.ajaxSubmit = function (t) {
    function r(t) {
      var r, a, n = e.param(t).split("&"), i = n.length, o = [];
      for (r = 0; i > r; r++) n[r] = n[r].replace(/\+/g, " "), a = n[r].split("="), o.push([decodeURIComponent(a[0]), decodeURIComponent(a[1])]);
      return o
    }

    function o(a) {
      for (var n = new FormData, i = 0; i < a.length; i++) n.append(a[i].name, a[i].value);
      if (t.extraData) {
        var o = r(t.extraData);
        for (i = 0; i < o.length; i++) o[i] && n.append(o[i][0], o[i][1])
      }
      t.data = null;
      var s = e.extend(!0, {}, e.ajaxSettings, t, {contentType: !1, processData: !1, cache: !1, type: u || "POST"});
      t.uploadProgress && (s.xhr = function () {
        var e = jQuery.ajaxSettings.xhr();
        return e.upload && e.upload.addEventListener("progress", function (e) {
          var r = 0, a = e.loaded || e.position, n = e.total;
          e.lengthComputable && (r = Math.ceil(a / n * 100)), t.uploadProgress(e, a, n, r)
        }, !1), e
      }), s.data = null;
      var l = s.beforeSend;
      return s.beforeSend = function (e, t) {t.data = n, l && l.call(this, e, t)}, e.ajax(s)
    }

    function s(r) {
      function n(e) {
        var t = e.contentWindow ? e.contentWindow.document : e.contentDocument ? e.contentDocument : e.document;
        return t
      }

      function o() {
        function t() {
          try {
            var e = n(g).readyState;
            a("state = " + e), e && "uninitialized" == e.toLowerCase() && setTimeout(t, 50)
          } catch (r) {a("Server abort: ", r, " (", r.name, ")"), s(D), j && clearTimeout(j), j = void 0}
        }

        var r = f.attr2("target"), i = f.attr2("action");
        w.setAttribute("target", p), u || w.setAttribute("method", "POST"), i != m.url && w.setAttribute("action", m.url), m.skipEncodingOverride || u && !/post/i.test(u) || f.attr({
          encoding: "multipart/form-data",
          enctype: "multipart/form-data"
        }), m.timeout && (j = setTimeout(function () {T = !0, s(k)}, m.timeout));
        var o = [];
        try {
          if (m.extraData) for (var l in m.extraData) m.extraData.hasOwnProperty(l) && (e.isPlainObject(m.extraData[l]) && m.extraData[l].hasOwnProperty("name") && m.extraData[l].hasOwnProperty("value") ? o.push(e('<input type="hidden" name="' + m.extraData[l].name + '">').val(m.extraData[l].value).appendTo(w)[0]) : o.push(e('<input type="hidden" name="' + l + '">').val(m.extraData[l]).appendTo(w)[0]));
          m.iframeTarget || (v.appendTo("body"), g.attachEvent ? g.attachEvent("onload", s) : g.addEventListener("load", s, !1)), setTimeout(t, 15);
          try {w.submit()} catch (c) {
            var d = document.createElement("form").submit;
            d.apply(w)
          }
        } finally {w.setAttribute("action", i), r ? w.setAttribute("target", r) : f.removeAttr("target"), e(o).remove()}
      }

      function s(t) {
        if (!x.aborted && !F) {
          try {M = n(g)} catch (r) {a("cannot access response document: ", r), t = D}
          if (t === k && x) return x.abort("timeout"), void S.reject(x, "timeout");
          if (t == D && x) return x.abort("server abort"), void S.reject(x, "error", "server abort");
          if (M && M.location.href != m.iframeSrc || T) {
            g.detachEvent ? g.detachEvent("onload", s) : g.removeEventListener("load", s, !1);
            var i, o = "success";
            try {
              if (T) throw"timeout";
              var u = "xml" == m.dataType || M.XMLDocument || e.isXMLDoc(M);
              if (a("isXml=" + u), !u && window.opera && (null === M.body || !M.body.innerHTML) && --O) return a("requeing onLoad callback, DOM not available"), void setTimeout(s, 250);
              var l = M.body ? M.body : M.documentElement;
              x.responseText = l ? l.innerHTML : null, x.responseXML = M.XMLDocument ? M.XMLDocument : M, u && (m.dataType = "xml"), x.getResponseHeader = function (e) {
                var t = {"content-type": m.dataType};
                return t[e]
              }, l && (x.status = Number(l.getAttribute("status")) || x.status, x.statusText = l.getAttribute("statusText") || x.statusText);
              var c = (m.dataType || "").toLowerCase(), f = /(json|script|text)/.test(c);
              if (f || m.textarea) {
                var p = M.getElementsByTagName("textarea")[0];
                if (p) x.responseText = p.value, x.status = Number(p.getAttribute("status")) || x.status, x.statusText = p.getAttribute("statusText") || x.statusText; else if (f) {
                  var h = M.getElementsByTagName("pre")[0], b = M.getElementsByTagName("body")[0];
                  h ? x.responseText = h.textContent ? h.textContent : h.innerText : b && (x.responseText = b.textContent ? b.textContent : b.innerText)
                }
              } else "xml" == c && !x.responseXML && x.responseText && (x.responseXML = X(x.responseText));
              try {L = _(x, c, m)} catch (y) {o = "parsererror", x.error = i = y || o}
            } catch (y) {a("error caught: ", y), o = "error", x.error = i = y || o}
            x.aborted && (a("upload aborted"), o = null), x.status && (o = x.status >= 200 && x.status < 300 || 304 === x.status ? "success" : "error"), "success" === o ? (m.success && m.success.call(m.context, L, "success", x), S.resolve(x.responseText, "success", x), d && e.event.trigger("ajaxSuccess", [x, m])) : o && (void 0 === i && (i = x.statusText), m.error && m.error.call(m.context, x, o, i), S.reject(x, "error", i), d && e.event.trigger("ajaxError", [x, m, i])), d && e.event.trigger("ajaxComplete", [x, m]), d && !--e.active && e.event.trigger("ajaxStop"), m.complete && m.complete.call(m.context, x, o), F = !0, m.timeout && clearTimeout(j), setTimeout(function () {m.iframeTarget || v.remove(), x.responseXML = null}, 100)
          }
        }
      }

      var l, c, m, d, p, v, g, x, b, y, T, j, w = f[0], S = e.Deferred();
      if (r) for (c = 0; c < h.length; c++) l = e(h[c]), i ? l.prop("disabled", !1) : l.removeAttr("disabled");
      if (m = e.extend(!0, {}, e.ajaxSettings, t), m.context = m.context || m, p = "jqFormIO" + (new Date).getTime(), m.iframeTarget ? (v = e(m.iframeTarget), y = v.attr2("name"), y ? p = y : v.attr2("name", p)) : (v = e('<iframe name="' + p + '" src="' + m.iframeSrc + '" />'), v.css({
        position: "absolute",
        top: "-1000px",
        left: "-1000px"
      })), g = v[0], x = {
        aborted: 0,
        responseText: null,
        responseXML: null,
        status: 0,
        statusText: "n/a",
        getAllResponseHeaders: function () {},
        getResponseHeader: function () {},
        setRequestHeader: function () {},
        abort: function (t) {
          var r = "timeout" === t ? "timeout" : "aborted";
          a("aborting upload... " + r), this.aborted = 1;
          try {g.contentWindow.document.execCommand && g.contentWindow.document.execCommand("Stop")} catch (n) {}
          v.attr("src", m.iframeSrc), x.error = r, m.error && m.error.call(m.context, x, r, t), d && e.event.trigger("ajaxError", [x, m, r]), m.complete && m.complete.call(m.context, x, r)
        }
      }, d = m.global, d && 0 === e.active++ && e.event.trigger("ajaxStart"), d && e.event.trigger("ajaxSend", [x, m]), m.beforeSend && m.beforeSend.call(m.context, x, m) === !1) return m.global && e.active--, S.reject(), S;
      if (x.aborted) return S.reject(), S;
      b = w.clk, b && (y = b.name, y && !b.disabled && (m.extraData = m.extraData || {}, m.extraData[y] = b.value, "image" == b.type && (m.extraData[y + ".x"] = w.clk_x, m.extraData[y + ".y"] = w.clk_y)));
      var k = 1, D = 2, A = e("meta[name=csrf-token]").attr("content"), E = e("meta[name=csrf-param]").attr("content");
      E && A && (m.extraData = m.extraData || {}, m.extraData[E] = A), m.forceSync ? o() : setTimeout(o, 10);
      var L, M, F, O = 50,
        X = e.parseXML || function (e, t) {return window.ActiveXObject ? (t = new ActiveXObject("Microsoft.XMLDOM"), t.async = "false", t.loadXML(e)) : t = (new DOMParser).parseFromString(e, "text/xml"), t && t.documentElement && "parsererror" != t.documentElement.nodeName ? t : null},
        C = e.parseJSON || function (e) {return window.eval("(" + e + ")")}, _ = function (t, r, a) {
          var n = t.getResponseHeader("content-type") || "", i = "xml" === r || !r && n.indexOf("xml") >= 0,
            o = i ? t.responseXML : t.responseText;
          return i && "parsererror" === o.documentElement.nodeName && e.error && e.error("parsererror"), a && a.dataFilter && (o = a.dataFilter(o, r)), "string" == typeof o && ("json" === r || !r && n.indexOf("json") >= 0 ? o = C(o) : ("script" === r || !r && n.indexOf("javascript") >= 0) && e.globalEval(o)), o
        };
      return S
    }

    if (!this.length) return a("ajaxSubmit: skipping submit process - no element selected"), this;
    var u, l, c, f = this;
    "function" == typeof t && (t = {success: t}), u = this.attr2("method"), l = this.attr2("action"), c = "string" == typeof l ? e.trim(l) : "", c = c || window.location.href || "", c && (c = (c.match(/^([^#]+)/) || [])[1]), t = e.extend(!0, {
      url: c,
      success: e.ajaxSettings.success,
      type: u || "GET",
      iframeSrc: /^https/i.test(window.location.href || "") ? "javascript:false" : "about:blank"
    }, t);
    var m = {};
    if (this.trigger("form-pre-serialize", [this, t, m]), m.veto) return a("ajaxSubmit: submit vetoed via form-pre-serialize trigger"), this;
    if (t.beforeSerialize && t.beforeSerialize(this, t) === !1) return a("ajaxSubmit: submit aborted via beforeSerialize callback"), this;
    var d = t.traditional;
    void 0 === d && (d = e.ajaxSettings.traditional);
    var p, h = [], v = this.formToArray(t.semantic, h);
    if (t.data && (t.extraData = t.data, p = e.param(t.data, d)), t.beforeSubmit && t.beforeSubmit(v, this, t) === !1) return a("ajaxSubmit: submit aborted via beforeSubmit callback"), this;
    if (this.trigger("form-submit-validate", [v, this, t, m]), m.veto) return a("ajaxSubmit: submit vetoed via form-submit-validate trigger"), this;
    var g = e.param(v, d);
    p && (g = g ? g + "&" + p : p), "GET" == t.type.toUpperCase() ? (t.url += (t.url.indexOf("?") >= 0 ? "&" : "?") + g, t.data = null) : t.data = g;
    var x = [];
    if (t.resetForm && x.push(function () {f.resetForm()}), t.clearForm && x.push(function () {f.clearForm(t.includeHidden)}), !t.dataType && t.target) {
      var b = t.success || function () {};
      x.push(function (r) {
        var a = t.replaceTarget ? "replaceWith" : "html";
        e(t.target)[a](r).each(b, arguments)
      })
    } else t.success && x.push(t.success);
    t.success = function (e, r, a) {for (var n = t.context || this, i = 0, o = x.length; o > i; i++) x[i].apply(n, [e, r, a || f, f])};
    var y = e('input[type=file]:enabled[value!=""]', this), T = y.length > 0, j = "multipart/form-data",
      w = f.attr("enctype") == j || f.attr("encoding") == j, S = n.fileapi && n.formdata;
    a("fileAPI :" + S);
    var k, D = (T || w) && !S;
    t.iframe !== !1 && (t.iframe || D) ? t.closeKeepAlive ? e.get(t.closeKeepAlive, function () {k = s(v)}) : k = s(v) : k = (T || w) && S ? o(v) : e.ajax(t), f.removeData("jqxhr").data("jqxhr", k);
    for (var A = 0; A < h.length; A++) h[A] = null;
    return this.trigger("form-submit-notify", [this, t]), this
  }, e.fn.ajaxForm = function (n) {
    if (n = n || {}, n.delegation = n.delegation && e.isFunction(e.fn.on), !n.delegation && 0 === this.length) {
      var i = {
        s: this.selector,
        c: this.context
      };
      return !e.isReady && i.s ? (a("DOM not ready, queuing ajaxForm"), e(function () {e(i.s, i.c).ajaxForm(n)}), this) : (a("terminating; zero elements found by selector" + (e.isReady ? "" : " (DOM not ready)")), this)
    }
    return n.delegation ? (e(document).off("submit.form-plugin", this.selector, t).off("click.form-plugin", this.selector, r).on("submit.form-plugin", this.selector, n, t).on("click.form-plugin", this.selector, n, r), this) : this.ajaxFormUnbind().bind("submit.form-plugin", n, t).bind("click.form-plugin", n, r)
  }, e.fn.ajaxFormUnbind = function () {return this.unbind("submit.form-plugin click.form-plugin")}, e.fn.formToArray = function (t, r) {
    var a = [];
    if (0 === this.length) return a;
    var i = this[0], o = t ? i.getElementsByTagName("*") : i.elements;
    if (!o) return a;
    var s, u, l, c, f, m, d;
    for (s = 0, m = o.length; m > s; s++) if (f = o[s], l = f.name, l && !f.disabled) if (t && i.clk && "image" == f.type) i.clk == f && (a.push({
      name: l,
      value: e(f).val(),
      type: f.type
    }), a.push({name: l + ".x", value: i.clk_x}, {
      name: l + ".y",
      value: i.clk_y
    })); else if (c = e.fieldValue(f, !0), c && c.constructor == Array) for (r && r.push(f), u = 0, d = c.length; d > u; u++) a.push({
      name: l,
      value: c[u]
    }); else if (n.fileapi && "file" == f.type) {
      r && r.push(f);
      var p = f.files;
      if (p.length) for (u = 0; u < p.length; u++) a.push({name: l, value: p[u], type: f.type}); else a.push({
        name: l,
        value: "",
        type: f.type
      })
    } else null !== c && "undefined" != typeof c && (r && r.push(f), a.push({
      name: l,
      value: c,
      type: f.type,
      required: f.required
    }));
    if (!t && i.clk) {
      var h = e(i.clk), v = h[0];
      l = v.name, l && !v.disabled && "image" == v.type && (a.push({name: l, value: h.val()}), a.push({
        name: l + ".x",
        value: i.clk_x
      }, {name: l + ".y", value: i.clk_y}))
    }
    return a
  }, e.fn.formSerialize = function (t) {return e.param(this.formToArray(t))}, e.fn.fieldSerialize = function (t) {
    var r = [];
    return this.each(function () {
      var a = this.name;
      if (a) {
        var n = e.fieldValue(this, t);
        if (n && n.constructor == Array) for (var i = 0, o = n.length; o > i; i++) r.push({
          name: a,
          value: n[i]
        }); else null !== n && "undefined" != typeof n && r.push({name: this.name, value: n})
      }
    }), e.param(r)
  }, e.fn.fieldValue = function (t) {
    for (var r = [], a = 0, n = this.length; n > a; a++) {
      var i = this[a], o = e.fieldValue(i, t);
      null === o || "undefined" == typeof o || o.constructor == Array && !o.length || (o.constructor == Array ? e.merge(r, o) : r.push(o))
    }
    return r
  }, e.fieldValue = function (t, r) {
    var a = t.name, n = t.type, i = t.tagName.toLowerCase();
    if (void 0 === r && (r = !0), r && (!a || t.disabled || "reset" == n || "button" == n || ("checkbox" == n || "radio" == n) && !t.checked || ("submit" == n || "image" == n) && t.form && t.form.clk != t || "select" == i && -1 == t.selectedIndex)) return null;
    if ("select" == i) {
      var o = t.selectedIndex;
      if (0 > o) return null;
      for (var s = [], u = t.options, l = "select-one" == n, c = l ? o + 1 : u.length, f = l ? o : 0; c > f; f++) {
        var m = u[f];
        if (m.selected) {
          var d = m.value;
          if (d || (d = m.attributes && m.attributes.value && !m.attributes.value.specified ? m.text : m.value), l) return d;
          s.push(d)
        }
      }
      return s
    }
    return e(t).val()
  }, e.fn.clearForm = function (t) {return this.each(function () {e("input,select,textarea", this).clearFields(t)})}, e.fn.clearFields = e.fn.clearInputs = function (t) {
    var r = /^(?:color|date|datetime|email|month|number|password|range|search|tel|text|time|url|week)$/i;
    return this.each(function () {
      var a = this.type, n = this.tagName.toLowerCase();
      r.test(a) || "textarea" == n ? this.value = "" : "checkbox" == a || "radio" == a ? this.checked = !1 : "select" == n ? this.selectedIndex = -1 : "file" == a ? /MSIE/.test(navigator.userAgent) ? e(this).replaceWith(e(this).clone(!0)) : e(this).val("") : t && (t === !0 && /hidden/.test(a) || "string" == typeof t && e(this).is(t)) && (this.value = "")
    })
  }, e.fn.resetForm = function () {return this.each(function () {("function" == typeof this.reset || "object" == typeof this.reset && !this.reset.nodeType) && this.reset()})}, e.fn.enable = function (e) {return void 0 === e && (e = !0), this.each(function () {this.disabled = !e})}, e.fn.selected = function (t) {
    return void 0 === t && (t = !0), this.each(function () {
      var r = this.type;
      if ("checkbox" == r || "radio" == r) this.checked = t; else if ("option" == this.tagName.toLowerCase()) {
        var a = e(this).parent("select");
        t && a[0] && "select-one" == a[0].type && a.find("option").selected(!1), this.selected = t
      }
    })
  }, e.fn.ajaxSubmit.debug = !1
}(jQuery);
