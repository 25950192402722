$(document).ready(function () {
  let $body = $('body');

  String.prototype.ellipsis = function (n) {
    return this.substr(0, n - 1) + (this.length > n ? '&hellip;' : '');
  };

  // events on body
  $body
    .addClass('loaded')
    .on('click', '.js-social--auth', function (e) {
      // авторизация через социалки
      e.preventDefault();

      let href = $(this).attr('href') || $(this).attr('data-href');
      window.open(href, '', 'toolbar=0,status=0,width=626,height=436')
    })
    .on('click', '*[data-gtagcategory]', function (e) {
      // gtag
      let data = $(this).data();
      if (typeof gtag === 'undefined') {
        return false;
      }
      gtag('event', 'click', {
        'event_category': data.gtagcategory,
        'event_label': data.gtaglabel
      });
    })
    .on('click', '.js--scroll', function () {
      $('html, body').animate({
        scrollTop: $($(this).attr('href')).offset().top
      }, 400);
      return false
    });

  // подтверждение аккаунта
  (function () {
    let confirmed = coreFunctions.getParameterByName("confirmed");
    if (confirmed !== '') {
      window.setTimeout(function () {
        jewelryModals.show('.js-modal--registration-congratulation');
        coreFunctions.setHistoryState(document.title, "/");
      }, 50);
    }
  })();

  // inputmask
  $('input[data-mask]').each(function () {
    let $t = $(this), mask = $t.data('mask');
    if (typeof $t.attr('disabled') === 'undefined') {
      $t.inputmask('remove').inputmask('' + mask);
    }
  });

  // datepicker
  $.datepicker.regional['ru'] = {
    closeText: 'Закрыть',
    prevText: 'Предыдущий',
    nextText: 'Следующий',
    currentText: 'Сегодня',
    monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
    dayNames: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
    dayNamesShort: ['вск', 'пнд', 'втр', 'срд', 'чтв', 'птн', 'сбт'],
    dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    weekHeader: 'Не',
    dateFormat: 'dd.mm.yy',
    firstDay: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: ''
  };
  $.datepicker.setDefaults($.datepicker.regional['ru']);
  $('.datepicker').each(function () {
    $(this).datepicker({
      changeMonth: true,
      changeYear: true,
      yearRange: "-1:+0"
    });
  });

  $.timepicker.regional['ru'] = {
    timeOnlyTitle: 'Выберите время',
    timeText: 'Время',
    hourText: 'Часы',
    minuteText: 'Минуты',
    secondText: 'Секунды',
    millisecText: 'Миллисекунды',
    timezoneText: 'Часовой пояс',
    currentText: 'Сейчас',
    closeText: 'Закрыть',
    timeFormat: 'HH:mm',
    amNames: ['AM', 'A'],
    pmNames: ['PM', 'P'],
    isRTL: false
  };
  $.timepicker.setDefaults($.timepicker.regional['ru']);

  $('.datetimepicker').each(function () {
    $(this).datetimepicker({
      controlType: 'select',
      minDateTime: new Date('2021-06-15T00:00:00'),
      maxDateTime: new Date('2021-08-15T23:59:59')
    })
  });

  (function () {
    $('form').each(function () {
      let $form = $(this), $submit = $form.find('.button'), $required = $form.find('[required]');

      if ($required.length > 0) {
        $submit.addClass('button--disabled');

        $required.on('keyup', function () {
          let $t = $(this), $f = $t.closest('form'), $s = $f.find('.button');

          setTimeout(function () {
            let enabled = true;
            $f.find('[required]').each(function () {
              if ($.trim($(this).val()).length < 2) {
                enabled = false;
              }
            });

            if (enabled) {
              $s.removeClass('button--disabled').removeAttr('disabled');
            } else {
              $s.addClass('button--disabled').attr('disabled', 'disabled').off('click');
            }
          }, 10);
        });
      }
    });

    // маска на сумму
    let _ch_bsum = document.getElementById('check_check_bsum');
    if (_ch_bsum !== null) {
      IMask(
        _ch_bsum,
        {
          mask: Number,
          min: 0,
          max: 1000000,
          thousandsSeparator: ' '
        });
    }
  })();

  $('input[type="file"]').on('change', function () {
    let currentInp = $(this),
      currentParent = currentInp.parent(),
      currentLabel = currentParent.find('.file__label');

    currentLabel.text(currentInp.val());
  });

  if ($(window).width() >= 950) {
    $('.popover__mark').on('mouseenter', function () {
      $('.popover__mark-inner', $(this)).show();
    });

    $('.popover__mark').on('mouseleave', function () {
      $('.popover__mark-inner', $(this)).hide();
    });
  } else {
    $('.popover__mark').on('click', function () {
      $('.popover__mark-inner', $(this)).toggle();
    });
  }

  /////// @todo: прочий код

  if ($(".js-phone").length) {
    $(".js-phone").mask("+7(999) 999-99-99");
  }

  // responsive navigation toggle button
  $("#resp-toggle-btn").on('click', function () {
    $("body").toggleClass('navTrue');
  })

  if ($('table').length){
    $('table').each(function(index, el) {
      var labels                  = [],
          currentTable            = $(this),
          currentTableWidth       = currentTable.width(),
          currentTableParentWidth = currentTable.parent().width();

      currentTable.find('th').each(function() {
        var currentItem = $(this),
            text        =  currentItem.text().trim(),
            tempObj     = {};

        tempObj['label']      = text;
        tempObj['resp-index'] = currentItem.data('resp-header-item');

        labels.push(tempObj);
      });

      currentTable.find('tbody tr').each(function() {
        var currentRow = $(this);

        currentRow.find('td').each(function(index, el) {
          var currentCell = $(this),
              currentText = currentCell.text().trim();

          if (labels[index]){
            currentCell.attr('data-resp-label', labels[index].label);
          }

        });
      });

    });
  }

  $(".faq__item").on('click', function(){
    $(this).toggleClass('state-active');
  })

});
