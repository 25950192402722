require("jquery");
require("jquery-ui");
require("jquery-ujs");
jQuery.migrateMute = true;
require('jquery-migrate');
require('packs/js/jquery-ui-timepicker-addon.js');

window.raf = require('raf');
import debounce from 'lodash.debounce';
window.debounce = debounce;

require('ismobilejs');
import isMobile from 'ismobilejs';
window.isMobile = isMobile();

require('packs/js/core_functions');
require('packs/js/DragDropTouch');
require('packs/js/jewelryModals');
require('packs/js/jewelryTabs');
require('packs/js/loader');
require('packs/js/multipart-form');
require('packs/js/social-share-button');
require('packs/js/js_sharing.js.erb');
require('packs/js/jquery.maskedinput');
require('packs/js/imask');
require('packs/js/drop-card');
require('packs/js/drop-check');

require('packs/js/js');
